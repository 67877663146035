<template>
    <div>
        <v-parallax
            src="../assets/images/slide1.jpg"
            :height="($vuetify.breakpoint.smAndUp)?250:180"
        >
            <v-container class="mt-auto">
                <v-row>
                    <v-col cols="12">
                        <h1 class="text-sm-h4 text-h5 font-weight-thin mb-4">
                            <v-icon :size="($vuetify.breakpoint.smAndUp)?65:45">
                                mdi-glass-mug-variant
                            </v-icon> <br>
                            Pedido recibido
                        </h1>
                    </v-col>
                </v-row>
            </v-container>
        </v-parallax>

        <v-container class="py-15">
            <p>Gracias. Tu pedido ha sido recibido.</p>
            <ul class="details-list">
                <li>
                    Número del pedido: <br> <strong>{{ order.number }}</strong>
                </li>
                <li>
                    Fecha: <br> <strong>{{ generateDate(order.created_at) }}</strong>
                </li>
                <li>
                    Total: <br> <strong>{{ order.total + order.delivery }}/Bs.</strong>
                </li>
                <li>
                    Método de pago: <br>
                    <strong v-if="order.payment=='cash_payment'">Pago Contraentrega</strong>
                    <strong v-if="order.payment=='bank_transfer'">Pago Qr/Transferencia Bancaria</strong>
                    <strong v-if="order.payment=='card_payment'">Pago tarjeta de débito/crédito</strong>
                </li>
            </ul>

            <div v-if="order.payment!=='cash_payment'">
                <h3>Nuestros detalles bancarios</h3>
                <h4>{{ payment.name }}:</h4>
                <ul class="details-list">
                    <li>
                        Banco: <br> <strong>{{ payment.bank }}</strong>
                    </li>
                    <li>
                        Número de cuenta: <br> <strong>{{ payment.number }}</strong>
                    </li>
                    <li>
                        Código Qr: <br>
                        <v-img :src="payment.image" width="200px"></v-img>
                    </li>
                </ul>
            </div>

            <h3>Detalles del pedido</h3>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left font-weight-black">
                            Producto
                        </th>
                        <th class="text-left font-weight-black">
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(prod, j) in order.products" :key="j">
                        <td>
                            {{ prod.name }} x {{ prod.pivot.quantity }}
                        </td>
                        <td>{{ prod.pivot.price * prod.pivot.quantity }} /Bs.</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Subtotal:</td>
                        <td class="font-weight-bold">{{ order.total }} /Bs.</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Costo de envío:</td>
                        <td class="font-weight-bold">{{ order.delivery }} /Bs.</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">TOTAL:</td>
                        <td class="font-weight-bold">{{ order.total + order.delivery }} /Bs.</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-container>

        <v-snackbar
            v-model="snackbar"
        >
            {{ msgSnackbar }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <!-- overlay -->
        <v-overlay :value="overlay" opacity=".95" z-index="10">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            overlay: false,
            snackbar: false,
            msgSnackbar: '',
            order: {},
            payment: {}
        };
    },
    created() {
        this.getEcommerceSetting()
        this.getDetailsOrder()
    },
    methods: {
        // generate date
        generateDate(date) {
            const d = new Date(date)
            const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
            return d.getDate() +' de '+ months[d.getMonth()] +' de '+ d.getFullYear()
        },
        async getEcommerceSetting() {
            await axios.post('/data-by-type/bank', {}, {
                headers: {
                    Authorization: 'Bearer ' + this.auth.token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response);
                if (response.data.length > 0) {
                    this.payment = JSON.parse(response.data[0].content)
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 404 || error.response.status == 500) {
                    this.snackbar = true
                    this.msgSnackbar = 'Error con el servidor, por favor vuelva a intentarlo más tarde.'
                }
            })
        },
        /**
         * get order
         */
        async getDetailsOrder() {
            this.overlay = true
            await axios.get(`/details-order/${this.$route.params.id}`, {
                headers: {
                    Authorization: 'Bearer ' + this.auth.token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                // console.log(response);
                this.order = response.data

                this.overlay = false
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 404 || error.response.status == 500) {
                    this.snackbar = true
                    this.msgSnackbar = 'Error con el servidor, por favor vuelva a intentarlo más tarde.'
                    this.$router.push('/')
                }

                this.overlay = false
            })
        }
    },
    computed: {
        auth () {
            return this.$store.state.auth;
        },
    },
}
</script>